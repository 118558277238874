import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

import { translations } from './translations';
import { defaultLocale } from './default';

addLocaleData([...en]);

const locale = defaultLocale;
const messages = translations[locale];

function withInternationalization(Component) {
  return function Wrapped(props) {
    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <Component {...props} />
      </IntlProvider>
    );
  };
}

export default withInternationalization;
