import { render, ComponentType } from 'preact';

function renderComponent(
  components: { [key: string]: ComponentType },
  node: HTMLElement
) {
  if (!node.dataset.preactComponent) {
    throw new Error(`[data-preact-component] attribute not found`);
  }

  const Component = components[node.dataset.preactComponent];

  if (!Component) {
    throw new Error(`Unknown component: ${node.dataset.preactComponent}`);
  }

  const props = node.dataset.preactProps
    ? JSON.parse(node.dataset.preactProps)
    : {};

  props.children = node.innerHTML;

  render(<Component {...props} />, node);
}

function renderComponents({
  components,
}: {
  components: { [key: string]: ComponentType };
}) {
  const nodes = document.querySelectorAll('[data-preact-component]');

  nodes.forEach((node) => renderComponent(components, node as HTMLElement));
}

export default { init: renderComponents };
