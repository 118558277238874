import 'bootstrap';
import Rails from '@rails/ujs';

import LazyMap from './src/partners/components/LazyMap';
import Trix from './src/partners/components/Trix';
import PhoneInput from './src/partners/components/PhoneInput';
import PreactComponents from './src/partners/lib/PreactComponents';
import IntlQuestions from './src/partners/components/Questions';

Rails.start();

window.onload = () => {
  PreactComponents.init({ components: { IntlQuestions } });
  PhoneInput.init();
  LazyMap.init();
  Trix.init();
};
